import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Alert, breadcrumbsClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import { Box, Grid, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import InfoText from '../components/InfoText';
import { OrderBookingCard } from '../components/order/OrderBookingCard';
import { OrderSummaryCard } from '../components/order/OrderSummaryCard';
import { OrderLoginCard } from '../components/order/OrderLoginCard';
import { OrderPaymentCard } from '../components/order/OrderPaymentCard';
import { OrderPreviewCard } from '../components/order/OrderPreviewCard';
import { OrderProgressDialog } from '../components/order/OrderProgressDialog';
import { OrderConfirmationDialog } from '../components/order/OrderConfirmationDialog';
import { DraftConfirmationDialog } from '../components/order/DraftConfirmationDialog';
import { OrderFailureDialog } from '../components/order/OrderFailureDialog';
import { PaymentFailureDialog } from '../components/order/PaymentFailureDialog';
import { OrderPaymentDialog } from '../components/order/OrderPaymentDialog';
import TermsAndConditionDialog from '../components/order/TermsAndConditionsDialog';
import { MessageBox } from '../components/ModalDialog';
import { EditionsCard } from '../components/order/EditionsCard';
import { PriceCard } from '../components/common/PriceCard.jsx';
import { OrderLayouts } from '../components/order/OrderLayouts';
import MsgPopper from '../components/common/MsgPopper';
import Stack from '@mui/material/Stack';
import format from 'date-fns/format';
import i18n, { localeMap, formatMap } from '../configuration/i18n';

import { useSelector, useDispatch } from 'react-redux';
import {
    buy,
    confirmEPayment,
    print2,
    resetPrintData,
    saveDraft,
    setEPayment,
    resetEPayment,
    resetOrder,
    updateOrder,
    initCreative,
    cancelOrder,
    deleteDraft,
    confirmTermsAndConditions
} from '../redux/Actions';
import {
    businessPartner,
    fullOrder,
    isTermsAndConditionsConfirmed,
    orderPrintUrl,
    orderResponse,
    draftResponse,
    userName,
    selectedOffer,
    isGuestAuthenticated,
    isUncommittedBP,
    features,
    orderToEdit,
    selectedEditionPart,
    desiredDate,
    orderPriceResponse,
    epaymentConfirmed,
    ePayment,
    isWSS,
    selectedEditionIds,
    cancelUrl,
    selectedPaymentType,
    selectedMarket
} from '../redux/Selectors';

import { openPaymentWindow, PaymentProvider } from '../frames/PaymentProvider';
import { DEVICE_DESKTOP, getPage } from '../common/navigationTools';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Order = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const _businessPartner = useSelector((state) => businessPartner(state));
    const _fullOrder = useSelector((state) => fullOrder(state));
    const _isTermsAndConditionsConfirmed = useSelector((state) => isTermsAndConditionsConfirmed(state));
    const _orderResponse = useSelector((state) => orderResponse(state));
    const _draftResponse = useSelector((state) => draftResponse(state));
    const _orderPrintUrl = useSelector((state) => orderPrintUrl(state));
    const _userName = useSelector((state) => userName(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    const _isUncommittedBP = useSelector((state) => isUncommittedBP(state));
    const _features = useSelector((state) => features(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));
    const _epaymentConfirmed = useSelector((state) => epaymentConfirmed(state));
    const _ePayment = useSelector((state) => ePayment(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _selectedEditionIds = useSelector((state) => selectedEditionIds(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _desiredDate = useSelector((state) => desiredDate(state));
    const _cancelUrl = useSelector((state) => cancelUrl(state));
    const _selectedPaymentType = useSelector((state) => selectedPaymentType(state));
    const _selectedMarket = useSelector(state => selectedMarket(state));

    const smallWidth = 900;

    const [upperHeight, setUpperHeight] = useState(((window.innerHeight - 195) / 3 * 2) - 5);
    const [lowerHeight, setLowerHeight] = useState(((window.innerHeight - 195) / 3) - 5);
    const [width, setWidth] = useState(window.innerWidth);
    const [layout, setLayout] = useState(_selectedOffer?.editionsSelectable ? 2 : 1);
    const [stackDirection, setStackDirection] = useState('row')
    const [dataComplete, setDataComplete] = useState(false);
    const [confirmedOrder, setConfirmedOrder] = useState(null);
    const [confirmedDraft, setConfirmedDraft] = useState(null);
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState('');
    const [msgAnchorRef, setMsgAnchorRef] = useState(null);
    const [displaySummary, setDisplaySummary] = useState(true);
    const [displayDate, setDisplayDate] = useState('');
    const [draftDisabled, setDraftDisabled] = useState(true);
    const [isDraft, setIsDraft] = useState(false);
    const [directDebitOpen, setDirectDebitOpen] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);

    const editionsCardRef = React.useRef(null);


    useEffect(() => {
        window.addEventListener('resize', listenResize);
        window.addEventListener('message', listenPayment);
        return (() => {
            window.removeEventListener('resize', listenResize);
            window.removeEventListener('message', listenPayment);
        });
    }, []);

    useEffect(() => {
        setLayout(displaySummary ? 4 : (_selectedOffer?.editionsSelectable ? 2 : 1));
        //      setLayout(_selectedOffer?.editionsSelectable ? 2 : 1 /* 3*/);
    }, [_selectedOffer]);

    useEffect(() => {
        if (_selectedOffer && _selectedEditionPart && _desiredDate && _orderPriceResponse && displayDate && displayDate !== '') {
            setDataComplete(true);
        } else {
            setDataComplete(false);
        }
    }, [_selectedOffer, _selectedEditionPart, _desiredDate, _orderPriceResponse, displayDate]);

    useEffect(() => {
        if (_orderPriceResponse) {
            var orderPrice = _orderPriceResponse.value;
            var displayDate = orderPrice?.effectiveDates?.map(eDate => format(new Date(eDate), formatMap[i18n.language], { locale: localeMap[i18n.language] }))
                .join(" \u2022 ");
            setDisplayDate(displayDate);
        }
    }, [_orderPriceResponse, i18n.language]);

    const listenResize = () => {
        setUpperHeight(((window.innerHeight - 195) / 3 * 2) - 5);
        setLowerHeight(((window.innerHeight - 195) / 3) - 5);
        setWidth(window.innerWidth);
        setStackDirection(window.innerWidth < smallWidth ? 'column' : 'row');
    };

    const getLoginCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : (!_businessPartner ? '40%' : '25%')
        }
    };

    const getPaymentCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : (!_businessPartner ? '40%' : '50%')
        }
    };

    const getPriceCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : (!_businessPartner ? '20%' : '25%')
        }
    };

    const back = () => {
        navigate(-1);
    }

    const buttonStyle = () => {
        return {
            /* marginRight: "1em",
             marginLeft: "1em",
             marginBottom: '1em',*/
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2
        }
    };

    const isOrderComplete = () => {
        return Boolean(_businessPartner) && dataComplete;
    };


    // --------------------------------------------------
    // booking processing
    // --------------------------------------------------
    const [progressOpen, setProgressOpen] = React.useState(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [draftConfirmationOpen, setDraftConfirmationOpen] = React.useState(false);
    const [failureMsgOpen, setFailureMsgOpen] = React.useState(false);

    const handleBuy = (confirmed) => {
        setIsDraft(false);
        setMsgOpen(false);
        if (!_isTermsAndConditionsConfirmed && !confirmed) {
            setTermsAndConditionsConfirmedOpen(true);
            return;
        }
        if (_selectedOffer?.editionsSelectable) {
            var minEditionsSelectable = _selectedOffer.minEditionsSelectable;
            var maxEditionsSelectable = _selectedOffer.maxEditionsSelectable;
            var editionCount = _selectedEditionIds ? _selectedEditionIds.length : 0;
            if (editionCount < minEditionsSelectable) {
                if (minEditionsSelectable === maxEditionsSelectable) {
                    setMsgText(t('order.order.minMaxEditionsSelectable', { count: editionCount, min: _selectedOffer.minEditionsSelectable, max: _selectedOffer.maxEditionsSelectable }));

                } else {
                    setMsgText(t('order.minEditionsSelectable', { count: editionCount, min: _selectedOffer.minEditionsSelectable }));
                }
                setMsgAnchorRef(editionsCardRef);
                setMsgOpen(true);
                return;
            }
        }
        if (_selectedPaymentType._key === 'paymentDirectDebit' && (!_businessPartner.bankAccounts || 0 === _businessPartner.bankAccounts.length)) {
            setDirectDebitOpen(true);
            return;
        }
        dispatch(buy(_fullOrder));
        setProgressOpen(true);
    }

    const handleSave = () => {
        if (!_isTermsAndConditionsConfirmed) {
            setTermsAndConditionsConfirmedOpen(true);
            return;
        }
        var upOrder = JSON.parse(JSON.stringify(_fullOrder));
        upOrder.orderCode = _orderToEdit.orderCode;
        dispatch(updateOrder(upOrder, _orderToEdit.orderCode));
        setProgressOpen(true);
    }

    const handleProgressClosed = () => {
        dispatch(initCreative());
        dispatch(resetOrder());
        navigate(getPage('templates', DEVICE_DESKTOP));
    };

    const handleDraftConfirmationClosed = () => {
        setDraftConfirmationOpen(false);
    };

    const handleOrderFailureClosed = () => {
        setProgressOpen(false);
        setFailureMsgOpen(false);
    }

    // --------------------------------------------------
    // payment handling
    // --------------------------------------------------
    const [paymentOpen, setPaymentOpen] = React.useState(false);
    const [paymentWindow, setPaymentWindow] = React.useState(null);
    const [paymentStatus, setPaymentStatus] = React.useState(null);
    const [paymentFailureOpen, setPaymentFailureOpen] = React.useState(false);
    const [orderPayment, setOrderPayment] = React.useState(null);

    const pwRef = useRef(null);
    const timerRef = useRef(null);

    const listenPayment = event => {
        if (event.data.paymentStatus) {
            setPaymentStatus(event.data.paymentStatus);
        }
        else if ('paymentCancelled' === event.data ||
            'paymentError' === event.data ||
            'paymentConfirmed' === event.data) {
            setPaymentStatus(event.data);
        }
    };

    const processPayment = payment => {
        dispatch(setEPayment(payment));
        switch (payment.method) {
            // https://docs.datatrans.ch/docs/redirect-lightbox
            // Avoid embedding our Redirect or Lightbox integration in an iframe
            // To avoid issues with our payment forms, avoid embedding them via an iFrame. 
            // By embedding the Redirect or Lightbox integration in an iframe, you may break some payment flows.
            // case 'datatrans':
            //     setPaymentOpen(true);
            //     break;
            // case 'paypal':
            //     setPaymentOpen(true);
            //     break;
            // case 'payunity':
            //     setPaymentOpen(true);
            //     break;
            default:
                pwRef.current = openPaymentWindow(payment);
                if (!pwRef.current) {
                    return null;
                }
                setPaymentWindow(pwRef.current);
                timerRef.current = setInterval(() => {
                    if (pwRef.current.closed) {
                        pwRef.current = null;
                        setPaymentWindow(null);
                        clearInterval(timerRef.current);
                    }
                }, 500);
                return pwRef.current;
        }
    }

    /**
     * process payment result
     */
    useEffect(() => {
        if (paymentStatus) {
            if (paymentWindow && !paymentWindow.closed) {
                paymentWindow.close();
                setPaymentWindow(null);
            }
            else {
                setPaymentOpen(false);
            }
            switch (paymentStatus.action) {
                case 'paymentConfirmed':
                    setConfirmationOpen(true);
                    dispatch(initCreative());
                    dispatch(confirmEPayment());
                    setPaymentStatus(null);
                    break;
                case 'paymentCancelled':
                    break;
                case 'paymentError':
                    setPaymentFailureOpen(true);
                    break;
                default:
                    console.log(`handlePaymentClosed() event=${paymentStatus}`);
            }
        }
    }, [paymentStatus]);


    const handleSaveDraft = (confirmed) => {
        setIsDraft(true);
        if (!_isTermsAndConditionsConfirmed && !confirmed) {
            setTermsAndConditionsConfirmedOpen(true);
            return;
        }
        dispatch(saveDraft(_fullOrder));
        setProgressOpen(true);
    };

    useEffect(() => {
        if (_orderResponse) {
            if (_orderResponse.success) {
                let co = _orderResponse.value;
                setProgressOpen(false);
                setConfirmedOrder(co);
                if (co.orderPayment && !_epaymentConfirmed) {
                    setOrderPayment(co.orderPayment);
                    if (!processPayment(co.orderPayment)) {
                        setPaymentFailureOpen(true);
                    }
                }
                else {
                    setConfirmationOpen(true);
                    if (_cancelUrl) {
                        if (_cancelUrl.includes("draft") !== -1) {
                            dispatch(deleteDraft(_cancelUrl));
                        } else {
                            dispatch(cancelOrder(_cancelUrl, false));
                        }
                    }
                }
            }
            else {
                setApiResponse(_orderResponse);
                setProgressOpen(false);
                setFailureMsgOpen(true);
            }
        }
    }, [_orderResponse]);

    useEffect(() => {
        if (_draftResponse) {
            if (_draftResponse.success) {
                let co = _draftResponse.value;
                setProgressOpen(false);
                setConfirmedDraft(co);
                setDraftConfirmationOpen(true);
                if (_cancelUrl) {
                    if (_cancelUrl.includes("draft") !== -1) {
                        dispatch(deleteDraft(_cancelUrl));
                    } else {
                        dispatch(cancelOrder(_cancelUrl, false));
                    }
                }
            }
            else {
                setApiResponse(_draftResponse);
                setProgressOpen(false);
                setFailureMsgOpen(true);
            }
        }
    }, [_draftResponse]);


    // --------------------------------------------------
    // terms and conditions
    // --------------------------------------------------

    const handleTermsAndConditionsDialog = confirmed => {
        setTermsAndConditionsConfirmedOpen(false);
        setTermsAndConditionsRequired(!confirmed);
        dispatch(confirmTermsAndConditions(confirmed));
        if (confirmed) {
            setTimeout(() => { isDraft ? handleSaveDraft(confirmed) : handleBuy(confirmed) }, 10);
        }

    };
    const [termsAndConditionsConfirmedOpen, setTermsAndConditionsConfirmedOpen] = React.useState(false);
    const [termsAndConditionsRequired, setTermsAndConditionsRequired] = React.useState(false);
    useEffect(() => {
        if (_isTermsAndConditionsConfirmed) {
            setTermsAndConditionsRequired(false);
        }
    }, [_isTermsAndConditionsConfirmed]);

    // ----------------------------------------
    // order printing
    // ----------------------------------------  
    const printHandler = () => {
        dispatch(print2(_fullOrder));
    };
    let printWindow = undefined;
    useEffect(() => {
        if (_orderPrintUrl) {
            let width = window.screen.width / 2;
            var left = window.screen.width / 4;
            let height = window.screen.height / 2;
            var top = window.screen.height / 4;
            let options = `left=${left},top=${top},width=${width},height=${height}`;
            console.log(options);
            printWindow = window.open(_orderPrintUrl, "webstorePrintWindow", options);
            dispatch(resetPrintData());
        }
    }, [_orderPrintUrl]);

    useEffect(() => {
        let disabled = !isOrderComplete() || (_features?.ASE_1992.active && (Boolean(_cancelUrl && !_cancelUrl.includes('draft')) || _selectedPaymentType?.forcePrePayment));
        setDraftDisabled(disabled);
    }, [_selectedPaymentType, _cancelUrl, _features, dataComplete, _businessPartner])

    const Action2Button = ({ action }) => {
        if (action === "saveDraft") {
            if (!_orderToEdit && !(_isGuestAuthenticated || _isUncommittedBP) && (((_features?.ASE_1021) && _isWSS) || _features?.ASE_1992.active)) {
                console.log("draftDisabled = " + draftDisabled);
                return <Button variant="navigationBack" disabled={draftDisabled} onClick={() => { handleSaveDraft(); }}>{t('order.saveDraft')}</Button>
            } else {
                return <></>
            }
        } else if (action === "buy") {
            if (!_orderToEdit) {
                let disabled = !isOrderComplete() || Boolean(_cancelUrl && _cancelUrl.includes('draft'));
                console.log("disabled = " + disabled);
                let variant = !disabled ? 'navigationAction' : 'contained';
                return <Button
                    sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }} variant="navigationAction" disabled={disabled} onClick={() => { handleBuy(); }}>
                    {t(!_selectedMarket.freeOfCharge ? 'order.book' : 'order.bookFree')}
                </Button>;
            }
            else {
                return <Button sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }} variant="navigationAction" onClick={() => { handleSave(); }}>{t('order.saveMotif')}</Button>
            }
        } else {
            return <></>
        }
    };

    return (
        <>
            <Stack direction="column" spacing={5}>
                <AppHeader></AppHeader>
                <Box>
                    <Box sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }} elevation={4} >
                        <Grid container direction="row" rowSpacing={4} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, pr: 0, height: width < smallWidth ? "100%" : upperHeight, mr: 0, ml: 0 }}>
                                    <Stack
                                        direction={stackDirection}
                                        spacing={4}
                                        sx={{ width: '100%', height: '100%', justifyContent: 'stretch' }}>
                                        <Box sx={OrderLayouts(layout, upperHeight, width).preview}>
                                            <OrderPreviewCard height={upperHeight} width={width < smallWidth ? "100%" : (layout === 1 ? width / 4 : width / 3)} printHandler={printHandler} />
                                        </Box>
                                        <Box sx={OrderLayouts(layout, upperHeight, width).orderdata} >
                                            {!_orderToEdit && !displaySummary && (<OrderBookingCard height={upperHeight - 60} layoutDisplayOfferAction={() => setLayout(3)} dataComplete={setDataComplete} />)}
                                            {(_orderToEdit || displaySummary) && (<OrderSummaryCard height={upperHeight - 60} />)}
                                        </Box>
                                        <Box sx={OrderLayouts(layout, upperHeight, width).editions} >
                                            <EditionsCard ref={editionsCardRef} height={upperHeight} width={width / 4} /*onClose={() => setLayout(1)}*/ />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, pr: 0, height: width < smallWidth ? "100%" : lowerHeight, mr: 0, ml: 0 }}>
                                    <Stack
                                        direction={stackDirection}
                                        spacing={4}
                                        sx={{ width: '100%', height: '100%', justifyContent: 'stretch' }}>
                                        <Box sx={getLoginCardStyle()} >
                                            <OrderLoginCard />
                                        </Box>
                                        <Box sx={getPaymentCardStyle()} >
                                            <OrderPaymentCard termsAndConditionsRequired={termsAndConditionsRequired} />
                                        </Box>
                                        <Box sx={getPriceCardStyle()} >
                                            <PriceCard displayPriceText={true} />
                                        </Box>

                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>

                                <Box width="100%" sx={buttonStyle()}>
                                    <Stack
                                        direction="row"
                                        spacing={4}
                                        sx={{ width: '100%', justifyContent: 'stretch' }}
                                    >
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <AppFooter
                    action1={<Button variant="navigationBack" onClick={() => { back(); }} >{t('order.back')}</Button>}
                    action2={<Action2Button action="saveDraft" />}
                    action3={<Action2Button action="buy" />}
                >

                </AppFooter>
                <InfoText />
            </Stack >
            <MessageBox open={directDebitOpen} handleClose={() => setDirectDebitOpen(false)} message={'order.accountRequired'} severity="error"></MessageBox>
            <TermsAndConditionDialog open={termsAndConditionsConfirmedOpen} handleClose={handleTermsAndConditionsDialog} />
            <OrderProgressDialog open={progressOpen}></OrderProgressDialog>
            <OrderConfirmationDialog handleClose={handleProgressClosed} open={confirmationOpen} order={confirmedOrder} userName={_userName} ></OrderConfirmationDialog>
            <DraftConfirmationDialog handleClose={handleDraftConfirmationClosed} open={draftConfirmationOpen} order={confirmedDraft} userName={_userName} ></DraftConfirmationDialog>
            <OrderFailureDialog handleClose={handleOrderFailureClosed} open={failureMsgOpen} apiResponse={apiResponse} />
            <PaymentFailureDialog handleClose={() => { setPaymentFailureOpen(false) }} open={paymentFailureOpen} paymentStatus={paymentStatus} orderPayment={orderPayment} />
            <OrderPaymentDialog open={paymentOpen} payment={_ePayment}></OrderPaymentDialog>
            {paymentWindow && (
                <PaymentProvider childWindow={paymentWindow}></PaymentProvider>
            )
            }
            <MsgPopper
                open={msgOpen}
                arrow={true}
                anchorRef={msgAnchorRef}
                placement='left'
                text={msgText}
            >
            </MsgPopper>

        </>
    );
}

export default Order;
