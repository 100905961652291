import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';

import MarketLink from './MarketLink';
import { device, marketsForSelection, preSelectedMarket, properties, selectedMarket } from '../redux/Selectors.js';
import { setMarket, setOffer } from '../redux/Actions.js';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Box, List, ListItemButton, ListItem, Stack, tabsClasses, Typography, Menu, MenuItem, Button } from '@mui/material';
import { TabList } from '@mui/lab';
import { TabContext } from '@mui/lab';
//import { styled } from '@mui/system';
import { Tab } from '@mui/material';
import { urlIsSvg, readSVG, SVGIcon } from '../common/SvgTools.js';
import { id } from 'date-fns/locale';

/**
 * 
 * @returns 
 */
const Desktop = ({ items, value, onMarketSelected }) => {

    const [activeTab, setActiveTab] = useState('999999');

    useEffect(() => {
        if (value) {
            setActiveTab(String(value));
        } else {
            setActiveTab('999999');
        }
    }, [value]);

    const tabButtonStyle = () => {

        return {
            mt: 3,
            pt: 0,
            ml: 0,
            pb: 3,
            minHeight: 50,
            '& .MuiButtonBase-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20
            },
            '& .MuiButtonBase-root-MuiTab-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20,
            },
            '& .MuiBadge-root': {
                maxHeight: 20,
                minWidth: 20,
            }
        }
    };

    return (<>
        <TabContext value={activeTab !== undefined ? activeTab : "999999"}>
            <TabList sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                },
                mt: 0,
                pt: 0,
                height: 30

            }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                value={activeTab !== undefined ? activeTab : "999999"}
                onChange={onMarketSelected}
            >
                {items.map(e =>
                    <Tab
                        key={e._id}
                        label=""
                        value={e._id !== undefined ? String(e._id) : '999999'}
                        iconPosition='start'
                        sx={tabButtonStyle}
                        //slots={{ root: MarketLink }}
                        component={MarketLink}
                        market={e}
                        selectedMarketId={value}
                        marketAction={onMarketSelected}
                    >
                    </Tab>
                )}
            </TabList>
        </TabContext>
    </>
    );
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const MarketIcon = ({ market, selected }) => {

    const href = selected && selected._id === market._id ? market._links.selectedMarketIcon.href : market._links.marketIcon.href;

    return market ?
        (<img style={{ height: "2rem" }} src={href} alt={market.name}></img>)
        :
        (<></>);
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const MarketSvgIcon = ({ market, selected, properties }) => {

    const color = selected && selected._id === market._id ? properties.appBackgroundColor : properties.appPrimaryColor;
    market.svg.fill = color;
    market.svg.name = market.name;

    const MSvgIcon = (props) => {
        //       return <></>
        return <SVGIcon svg={market.svg} {...props} />;
    }

    return market ?
        //    (<img style={{ height: "2rem", fill: color }} src={href} alt={market.name}></img>)
        (<MSvgIcon sx={{ fontSize: "2rem" }} />)
        :
        (<></>);
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const Mobile = ({ items, selected, onMarketSelected, properties }) => {

    const [itemsWithType, setItemsWithType] = useState(JSON.parse(JSON.stringify(items)));
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const refs = useRef([]);

    useEffect(() => {
        createMarketList(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        if (refs.current[focusedIndex]) {
            refs.current[focusedIndex].focus();
        }
    }, [focusedIndex]);


    //   let list = [];
    async function createMarketList(items) {
        let l = JSON.parse(JSON.stringify(items));
        let list = [];
        for await (let item of l) {
            let isSVG = await urlIsSvg(item._links.marketIcon.href);
            if (isSVG === true) {
                item.type = 'svg';
                let svg = await readSVG(item._links.marketIcon.href);
                item.svg = svg;
            } else {
                item.type = 'png';
            }
            list.push(item);
        }
        setItemsWithType(list);
    }





    const marketStyle = market => {
        let c, bc;
        if (selected && selected._id === market._id) {
            c = properties.appBackgroundColor;
            bc = properties.appPrimaryColor;
        }
        else {
            c = properties.appPrimaryColor;
            bc = properties.appBackgroundColor;
        }

        return (
            {
                padding: '0.2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                color: c,
                backgroundColor: bc,
                border: '1px solid',
                borderRadius: '10px',
                cursor: 'pointer',
                width: "100%",
                mb: 6
            }
        );
    };

    const marketTypographyStyle = market => {
        return (
            {
                color: selected && selected._id === market._id ? properties.appBackgroundColor : properties.appPrimaryColor,
                fontSize: 28
            }
        );
    }


    const onMarketKeyPress = (e, id) => {
        console.log("onMarketKeyPress " + e.key + "/" + e.type + " marketid = " + id);
        console.log(e)

        switch (e.key) {
            case 'ArrowDown':
                setFocusedIndex((prevIndex) =>
                    prevIndex === items.length - 1 ? 0 : prevIndex + 1
                );
                break;
            case 'ArrowUp':
                setFocusedIndex((prevIndex) =>
                    prevIndex === 0 ? items.length - 1 : prevIndex - 1
                );
                break;
            case 'Enter':
            case ' ':
                onMarketSelected(e, id);
                break;
            default:
                break;
        }
    }

    const MarketButton = market => {
        let marketItem = market.market;
        console.log(marketItem)
        return (
            <Box
                key={marketItem._id}
                sx={marketStyle(marketItem)}
                onClick={(e) => onMarketSelected(e, marketItem._id)}
                onKeyUp={(e) => onMarketKeyPress(e, marketItem._id)}
                onKeyDown={(e) => console.log(e)}
            >
                <Stack spacing={5} direction="row" alignItems="center" justifyContent="left" tabIndex={-1}>
                    {marketItem.type === "png" && (<MarketIcon market={marketItem} selected={selected}></MarketIcon>)}
                    {marketItem.type === "svg" && (<MarketSvgIcon market={marketItem} selected={selected} properties={properties}></MarketSvgIcon>)}
                    <Typography
                        variant='marketPickerTitle'
                        sx={marketTypographyStyle(marketItem)}
                    >{marketItem.name}
                    </Typography>
                </Stack>
            </Box>
        )
    }

    return (
        <>
            <List tabIndex={0}>
                {itemsWithType?.map((item, index) =>
                    <ListItem disablePadding
                        onKeyDown={(e) => onMarketKeyPress(e, item._id)}
                        tabIndex={0}
                        ref={(el) => (refs.current[index] = el)}
                    >
                        <ListItemButton
                            component={MarketButton}
                            market={item}
                            index={index}

                        />
                    </ListItem>
                )}
            </List>
        </>
    );

};



/**
 * 
 * @returns 
 */
export const MarketPicker = ({ next }) => {

    const ID_ALLMARKETS = 999999;
    const dispatch = useDispatch();
    const _device = useSelector((state) => device(state));
    const _markets = useSelector((state) => marketsForSelection(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _preSelectedMarket = useSelector((state) => preSelectedMarket(state));

    const [value, setValue] = useState(_selectedMarket?._id || ID_ALLMARKETS);
    //   const value = _selectedMarket?._id || ID_ALLMARKETS;

    useEffect(() => {
        setValue(_selectedMarket?._id ? _selectedMarket?._id : ID_ALLMARKETS);
    }, [_selectedMarket, _markets])

    useEffect(() => {
        if (_preSelectedMarket && !_selectedMarket) {
            setValue(String(_preSelectedMarket._id));
            dispatch(setMarket(_preSelectedMarket._id));
        }
    }, [_preSelectedMarket]);

    const onMarketSelected = (e, id) => {
        const marketId = id ? id : e ? e.target ? e.target.id : Number(e._id) : null;
        dispatch(setMarket(marketId));
        if (ID_ALLMARKETS === marketId) {
            dispatch(setOffer());
        }
        if (next) {
            next();
        }

    };

    if ('desktop' === _device) {
        return <Desktop items={_markets} value={value} onMarketSelected={onMarketSelected} selected={_selectedMarket} properties={_properties}></Desktop>
    }
    else if ('mobile' === _device) {
        return <Mobile items={_markets.filter(e => e._id !== ID_ALLMARKETS)} value={value} onMarketSelected={onMarketSelected} selected={_selectedMarket} properties={_properties}></Mobile>
    }
    else {
        return <></>
    }

}

export default MarketPicker; 