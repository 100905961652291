import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, IconButton, Paper, Typography } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ShortcutRoundedIcon from '@mui/icons-material/ShortcutRounded';

export const PaymentFailureDialog = ({ open, handleClose, paymentStatus, orderPayment }) => {

    const { t } = useTranslation();
    if (!paymentStatus || !orderPayment) {
        return (
            <Dialog fullScreen open={open}>
                <Paper sx={{ ml: '2rem', mr: '2rem', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                    <Typography
                        sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold' }}>{t('order.paymentFailure.title')}</Typography>
                    <Typography
                        sx={{ textAlign: 'center' }}
                        dangerouslySetInnerHTML={{ __html: t('browser.popupBlocker') }}>
                    </Typography>

                    <Button sx={{ marginTop: '10vh', textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>Ok</Button>
                </Paper >
            </Dialog >
        )
    }
    return (
        <Dialog fullScreen open={open}>
            <Paper sx={{ ml: '2rem', mr: '2rem', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <Typography
                    sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold' }}>{t('order.paymentFailure.title')}</Typography>
                <Typography
                    sx={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: t('order.paymentFailure.description', { orderCode: orderPayment?.orderCode, trxId: paymentStatus?.trxId }) }} >
                </Typography>

                <Button sx={{ marginTop: '10vh', textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>Ok</Button>
            </Paper >
        </Dialog >
    );
}
