import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Grid, Paper, Stack, Typography } from '@mui/material';
import { DataBold, Label } from '../../components/common/Commons';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdfOutlined';

export const OrderFailureDialog = ({ open, handleClose, message, apiResponse }) => {

    const { t } = useTranslation();

    const [msg, setMsg] = useState(t('order.orderFailure'));

    useEffect(() => {
        setMsg(message ? message : t('order.orderFailure'));
    }, [message]);

    const orderDetails = () => {
        return (
            <Stack container direction="column" spacing={5} sx={{ width: '90%', margin: 'auto' }}>
                {apiResponse.originalRequest.orderCode && (
                    <>
                        <Label text='order.orderCode' />
                        <DataBold text={apiResponse.originalRequest.orderCode} />
                    </>
                )}
                <Label text='customerdata.businessPartnerNo' />
                <DataBold text={apiResponse.originalRequest.businessPartnerNumber} />
                <Label text='order.orderFailure.date' />
                <DataBold text={new Date().toTimeString()} />
            </Stack>
        );
    };

    const getMessage = () => {
        return apiResponse?.originalRequest ? t('order.orderFailure.message') : t('order.orderFailure');
    };

    const handleDownload = () => {
        document.getElementById('motif-download').click();
    };

    const Download = () => {
        return (<a style={{ visibility: "hidden" }}
            id="motif-download"
            href={apiResponse.props["motif-download"]}
            target="_blank"
            download={`${apiResponse.originalRequest.motif.motifUuid}.pdf`}>
        </a>);
    }

    return (
        <Dialog fullScreen open={open}>
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <Typography
                    sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold' }}>{t('order.orderFailure.title')}</Typography>
                <Typography
                    sx={{ textAlign: 'center', width: '90%', }}
                    dangerouslySetInnerHTML={{ __html: getMessage() }}></Typography>
                <Box sx={{ marginTop: '5vh' }} >
                    {apiResponse?.originalRequest && orderDetails()}
                </Box>
                <Stack direction="row" spacing={10} sx={{ marginTop: '10vh' }}>
                    <Button ssx={{ textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>Ok</Button>
                    {apiResponse?.originalRequest && <>
                        <Download></Download>
                        <Button sx={{ textTransform: 'none' }} startIcon={<PictureAsPdfIcon />} variant="navigationBack" onClick={handleDownload}>{t('order.motifDownload')}</Button>
                    </>}
                </Stack>
            </Paper >
        </Dialog >
    );
}
