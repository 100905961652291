import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
    adBoxNumber,
    adBoxNumberTypes,
    assetBaseUrl,
    busy,
    customerType,
    designAlternatives,
    features,
    formxValid,
    isWSS,
    motifUuid,
    offerPrices,
    offers,
    orderToEdit,
    orderToRepeat,
    properties,
    selectedMarket,
    selectedOffer,
    selectedTemplate,
    styloReady,
    upsellingAlternatives,
    upsellingDone,
} from '../redux/Selectors.js';
import {
    fetchDesignAlternatives,
    fetchUpsellingAlternatives,
    setOffer,
    resetEditedOrder,
    resetRepeatedOrder,
    setStyloReady,
    setFormxReady,
} from '../redux/Actions.js';
import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import Stylo from '../frames/Stylo';
import Formx from '../frames/Formx';
import Preview from '../components/Preview';
import AlternativesPicker from '../components/creative/AlternativesPicker';
import AreaCardHeader from '../components/common/AreaCardHeader';
import UpsellingDialog from '../components/creative/UpsellingDialog';
import { CreativeLayouts } from '../components/creative/CreativeLayouts';
import { CreativeToolAreaLayouts } from '../components/creative/CreativeToolAreaLayouts';
import { CreativeConfigCard, CreativeConfigPriceCard } from '../components/creative/CreativeConfigCard.jsx';
import { CollapseablePriceCard as PriceCard } from '../components/common/PriceCard.jsx';
import { InactiveCard } from '../components/InactiveCard.jsx';
import InfoText from '../components/InfoText';
import Collapse from '@mui/material/Collapse';
import { DEVICE_DESKTOP, getPage } from '../common/navigationTools.js';
import { isMotifColourChangeable, motifHasOptions } from '../common/tools.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Creative = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const _adBoxNumber = useSelector((state) => adBoxNumber(state));
    const _adBoxNumberTypes = useSelector((state) => adBoxNumberTypes(state));
    const _alternatives = useSelector((state) => designAlternatives(state));
    const _assetBaseUrl = useSelector((state) => assetBaseUrl(state));
    const _busy = useSelector(state => busy(state));
    const _customerType = useSelector((state) => customerType(state));
    const _features = useSelector((state) => features(state));
    const _formxValid = useSelector((state) => formxValid(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _motifUuid = useSelector((state) => motifUuid(state));
    const _offerPrices = useSelector((state) => offerPrices(state));
    const _offers = useSelector((state) => offers(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _orderToRepeat = useSelector((state) => orderToRepeat(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _styloReady = useSelector((state) => styloReady(state));
    const _upsellingAlternatives = useSelector((state) => upsellingAlternatives(state));

    const [layout, setLayout] = useState(_selectedTemplate && _selectedTemplate._links.formx ? 1 : 4);
    const [toolAreaLayout, setToolAreaLayout] = useState(_orderToEdit ? 5 : 4);
    const [validateForm, setValidateForm] = useState(false);
    const [hasAlternatives, setHasAlternatives] = useState(false);
    const [displayConfigCard, setDisplayConfigCard] = useState(false);
    const [displayConfigCardWithFormx, setDisplayConfigCardWithFormx] = useState(false);
    const [height, setHeight] = useState(((window.innerHeight - 195) / 3 * 2) - 5);
    const [width, setWidth] = useState(window.innerWidth);
    const [fullLayout, setFullLayout] = useState(hasAlternatives);
    const [layoutSelected, setLayoutSelected] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [onlyEdit, setOnlyEdit] = useState(_orderToEdit ? true : false);
    const [msgAdboxOpen, setMsgAdboxOpen] = useState(false);
    const [fullOptionsCard, setFullOptionsCard] = useState(true);
    const [heightOptionsCard, setHeigthOptionsCard] = useState(0);
    const [isFormxHidden, setIsFormxHidden] = useState(false);

    const stylo = useRef(null);
    const formx = useRef(null);
    const priceCardRef = useRef(null);
    const alternativesCardRef = useRef(null);
    const creativeToolCardRef = useRef(null);
    const creativeConfigCardRef = useRef(null);

    let refFullLayout = useRef(false);

    const _upsellingDone = useSelector((state) => upsellingDone(state));
    const [upsellingOpen, setUpsellingOpen] = useState(false);

    const collapsedSize = 38;

    useEffect(() => {
        window.addEventListener('resize', listenResize);
        return () => {
            dispatch(setStyloReady(false));
            dispatch(setFormxReady(false));
            window.removeEventListener('resize', listenResize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (_selectedTemplate?.creativeDefaultLayout) {
            setLayout(_selectedTemplate.creativeDefaultLayout);
        } else if (_selectedTemplate?.creativeLayouts[0]) {
            setLayout(_selectedTemplate?.creativeLayouts[0]);
        } else {
            setLayout(_selectedTemplate && _selectedTemplate._links.formx ? 1 : 4);
        }
        setIsFormxHidden(_selectedTemplate?.formxName && _selectedTemplate?.creativeDefaultLayout === 4);
    }, [_selectedTemplate]);

    useEffect(() => {
        if (layoutSelected === false) {
            let fullH = !hasAlternatives || _orderToEdit ? false : true;
            console.log("useEffect [_alternatives, _orderToEdit]: setFullLayout to " + fullH);
            setFullLayout(fullH);
        }
    }, [hasAlternatives, _orderToEdit, layoutSelected]);

    useEffect(() => {
        if (_orderToEdit) {
            setToolAreaLayout(5);
        } /* else if (!hasAlternatives()) {
            setToolAreaLayout(6);
        } */ else if (!motifHasOptions(_selectedTemplate, _adBoxNumberTypes)) {
            // no options
            setToolAreaLayout(7);
        } else {
            setToolAreaLayout(4);
        }
        setHasAlternatives(!(_alternatives === undefined || _alternatives.length === 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_alternatives, _orderToEdit]);

    useEffect(() => {
        if (_selectedTemplate) {
            setDisplayConfigCard(/*_properties?.displayOfferPage && */!hasAlternatives && !(_selectedTemplate?.formxName && !isFormxHidden));
            console.log("set displayConfigCardWithFormx to " + Boolean(/*_properties.displayOfferPage && */!hasAlternatives && (_selectedTemplate.formxName && !isFormxHidden)));
            setDisplayConfigCardWithFormx(Boolean(/*_properties.displayOfferPage &&*/ !hasAlternatives && (_selectedTemplate.formxName && !isFormxHidden)));
        }
    }, [hasAlternatives, _orderToEdit, _properties, isFormxHidden, _selectedTemplate]);

    useEffect(() => {
        if (hasAlternatives && _styloReady) {
            stylo.current.setCollapseButton(true);
        }
    }, [hasAlternatives, _styloReady]);

    useEffect(() => {
        console.log("useEffect: fullLayout: " + fullLayout);
        resizeHeight(fullLayout);
        refFullLayout.current = fullLayout;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullLayout]);

    useEffect(() => {
        if (!_adBoxNumber) {
            setMsgAdboxOpen(false);
        }
    }, [_adBoxNumber]);

    useEffect(() => {
        console.log("useEffect validateForm = " + validateForm);
        if (validateForm && formx?.current?.isComplete() && stylo?.current?.isComplete()) {
            stylo.current.save(getNextPage());
            console.log("setValidateForm false");
            setValidateForm(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_formxValid, validateForm]);

    useEffect(() => {
        let hoc = 50;
        // slider columns
        if (_selectedTemplate?.unitWidth === 'COLUMNS' &&
            _selectedTemplate?.minWidth !== _selectedTemplate?.maxWidth) {
            hoc = hoc + 50;
        }
        // switch colors
        if (isMotifColourChangeable(_selectedTemplate)) {
            hoc = hoc + 50;
        }
        // switch adbox
        if (_adBoxNumberTypes && _selectedTemplate?.allowAdBox) {
            hoc = hoc + 50;
        }
        // display price
        //    if (/*_selectedOffer && _offerPrices[_selectedOffer._id] && */ layout !== 2) {
        hoc = hoc + 60;
        //    }
        console.log("useEffect for heightOptionsCard -> layout = " + layout);
        console.log("useEffect for heightOptionsCard -> set heightOptionsCard to " + hoc);
        setHeigthOptionsCard(hoc);
    }, [_selectedTemplate, _offerPrices, _selectedOffer, layout, _adBoxNumberTypes])


    const listenResize = () => {
        console.log("listenResize: fullLayout: " + refFullLayout.current);
        resizeHeight(refFullLayout.current);
        setWidth(window.innerWidth);
    };

    const getNextPage = () => {
        if (_orderToEdit) {
            return (getPage('order', DEVICE_DESKTOP));
        } else if (_offers.length > 1 || (_offers.length === 1 && _offers[0].editionsSelectable === true)) {
            return (getPage('offers', DEVICE_DESKTOP));
        } else {
            return (getPage('schedule', DEVICE_DESKTOP));
        }
    }

    const resizeHeight = (fullL) => {
        var h = 0;
        if (fullL === false) {
            h = hasAlternatives ? window.innerHeight - 185 - collapsedSize : window.innerHeight - 185;
            console.log("resizeHeight: fullLayout: false");
        } else {
            h = ((window.innerHeight - 195) / 3 * 2) - 5;
            console.log("resizeHeight: fullLayout: true");
        }
        console.log("listenResize: height = " + h);
        setHeight(h);

    }

    const handleExpand = (fl) => {
        console.log(fl);
        setLayoutSelected(true);
        if (fl === true) {
            console.log("handleExpand: fullLayout: true");
            stylo.current.setCollapseButton(false);
            priceCardRef.current.setExpanded(false);
            creativeToolCardRef.current.setExpanded(false);
            alternativesCardRef.current.setExpanded(false);
            console.log("handleExpand: setFullLayout false");
            setFullLayout(false);
            //           setHeight(window.innerHeight - 200 - collapsedSize);
        } else {
            console.log("handleExpand: fullLayout: false");
            stylo.current.setCollapseButton(true);
            priceCardRef.current.setExpanded(true);
            creativeToolCardRef.current.setExpanded(true);
            alternativesCardRef.current.setExpanded(true);
            console.log("handleExpand: setFullLayout true");
            setFullLayout(true);
            //           setHeight(((window.innerHeight - 195) / 3 * 2) - 5);
        }
    }

    const handleExpandOptionsCard = () => {
        console.log("handleExpandOptionsCard fullOptionsCard = " + fullOptionsCard);
        if (fullOptionsCard === true) {
            setFullOptionsCard(false);
            creativeConfigCardRef.current.setExpanded(false);
        } else {
            setFullOptionsCard(true);
            creativeConfigCardRef.current.setExpanded(true);
        }
    }

    const changeToolArea = () => {
        toolAreaLayout === 4 || toolAreaLayout === 7 ? setToolAreaLayout(1) : (!isMotifColourChangeable(_selectedTemplate, _adBoxNumberTypes) ? setToolAreaLayout(7) : setToolAreaLayout(4));
    }

    const back = async () => {
        // de-select offer
        dispatch(setOffer());
        //?????        dispatch(setTemplate());
        // eslint-disable-next-line no-unused-vars
        if (_orderToEdit || _orderToRepeat) {
            dispatch(resetEditedOrder());
            dispatch(resetRepeatedOrder);
            //            stylo.current.save(-1);
            navigate(-1);
        } else {
            navigate(getPage('templates', DEVICE_DESKTOP));
            //            stylo.current.save('/webstore/app/templates');
        }
        //        stylo.current.getTextflows();
    }

    const next = async () => {
        //     formx?.current?.validateForm();

        if (_selectedTemplate?.forceAdBox && !_adBoxNumber) {
            console.log("next: setFullLayout true");
            setFullLayout(true);
            setToolAreaLayout(4);
            setMsgAdboxOpen(true);
        } else if (stylo.current.isComplete() && (!_selectedTemplate.formxName || isFormxHidden)) {
            // eslint-disable-next-line no-unused-vars
            let id = await stylo.current.save();
            if (!_isWSS && _properties.forceUpselling && !_upsellingDone && _upsellingAlternatives?.length && !_orderToEdit) {
                setUpsellingOpen(true);
            }
            else {
                navigate(getNextPage());
            }
        } else if (_selectedTemplate.formxName && !isFormxHidden) {
            console.log("setValidateForm true");

            setValidateForm(true);
            formx.current?.validateForm();
        }
    }


    const styloHandler = (e, data) => {
        switch (e) {
            case 'loading':
                dispatch(setStyloReady(false));
                break;
            case 'loaded':
                if (isASE_1992 && _customerType === 'business') {
                    stylo.current.setPdfButton();
                }
                if (hasAlternatives) {
                    stylo.current.setCollapseButton(true);
                }
                dispatch(fetchDesignAlternatives());
                dispatch(fetchUpsellingAlternatives());
                break;
            case 'resize':
                handleExpand(data.expand);
                break;
            case 'filepdf':
                pdf();
                break;
            default:
                break;
        }
    };

    const opacity = (styloReady) => {
        return styloReady ? 1.0 : 0.1;
    }

    const SelectedMarket = () => {
        return (
            <Box sx={{ ml: 2, mt: 1, mr: 2, mb: 0 }}>
                <Typography variant='pageTitle'>
                    {t('creative.market')}
                </Typography>&nbsp;
                <Typography variant='pageTitleBold' >
                    {_selectedMarket?.name}
                </Typography>
            </Box>);
    }

    const handleUpsellingClose = (action) => {
        setUpsellingOpen(false);
        //        dispatch(setUpsellingDone(true));
        if ('next' === action) {
            navigate(getNextPage());
        }
        else if ('back' === action) {
            navigate(getPage('creative', DEVICE_DESKTOP));
        }
    };

    const isASE_1992 = () => {
        return _features && _features.ASE_1992 && _features.ASE_1992.status === "on";
    };

    const pdfUrl = (id) => {
        return _assetBaseUrl + '/' + id + '.pdf';
    };

    const pdf = async () => {
        await stylo.current.save();
        document.getElementById('motif-download-as-pdf').click();
        // window.open(pdfUrl(_motifUuid), "webstorePdfWindow");
    };

    return (
        <>
            <Stack direction="column" spacing={5}>
                <AppHeader></AppHeader>
                <Box>
                    <Box sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }} >

                        <SelectedMarket />
                        <Grid container direction="row" justifyContent="space-between">

                            <Grid item xs={12} justify-xs-flex-end="true">
                                <section style={{ position: 'relative' }}>
                                    <Box
                                        sx={{ opacity: opacity(styloReady), display: 'flex', p: 2, marginLeft: '1em', height: { height }, mr: 0, ml: 0, pr: 0 }}>
                                        <Stack
                                            direction='row'
                                            spacing={4}
                                            sx={{ width: '100%', height: '100%' }}>
                                            <Paper sx={CreativeLayouts(layout).stylo}>
                                                <Stylo ref={stylo} eventHandler={styloHandler} motifUuid={_motifUuid}></Stylo>
                                            </Paper>
                                            <Stack
                                                direction='column'
                                                spacing={4}
                                                sx={CreativeLayouts(layout).form}>
                                                <Card
                                                    variant='outlined'
                                                >
                                                    <AreaCardHeader
                                                        sx={{ height: 38 }}
                                                        titleTypographyProps={{ variant: 'areaCardHeader' }}
                                                        title={_selectedMarket?.name}
                                                        fullCard={!displayConfigCardWithFormx ? fullLayout : fullOptionsCard}
                                                        handleExpand={!displayConfigCardWithFormx ? handleExpand : handleExpandOptionsCard}
                                                        changeLayout={setLayout}>
                                                    </AreaCardHeader>
                                                    <CardContent
                                                        sx={{ height: !displayConfigCardWithFormx ? height : (fullOptionsCard ? height - heightOptionsCard - (2 * collapsedSize) : height - collapsedSize) }}>
                                                        {_selectedTemplate?.formxName && /*!isFormxHidden &&*/ (<Formx ref={formx} />)}
                                                    </CardContent>

                                                </Card>
                                                {displayConfigCardWithFormx && (<Box sx={{ height: fullOptionsCard ? heightOptionsCard + 38 : 38, width: '100%' }} >
                                                    <CreativeConfigPriceCard
                                                        ref={creativeConfigCardRef}
                                                        height={fullOptionsCard ? heightOptionsCard : 0}
                                                        expand={fullOptionsCard}
                                                        handleExpand={handleExpandOptionsCard}
                                                        msgOpen={msgAdboxOpen}
                                                        direction={CreativeLayouts(layout).creativetools.direction}
                                                        creativeStyle={CreativeLayouts(layout).creativetools.style}
                                                        displayOnlyPrice={motifHasOptions(_selectedTemplate, _adBoxNumberTypes) ? false : true}
                                                    />
                                                </Box>)}
                                            </Stack>
                                            <Paper sx={CreativeLayouts(layout).preview}>
                                                <Preview></Preview>
                                            </Paper>
                                        </Stack>
                                        {!fullLayout && displayConfigCard && (<Box sx={{ height: fullOptionsCard ? heightOptionsCard + 38 : 38, width: '25%', position: 'absolute', bottom: 6, right: 0 }} >
                                            <CreativeConfigPriceCard
                                                ref={creativeConfigCardRef}
                                                expand={fullOptionsCard}
                                                handleExpand={handleExpandOptionsCard}
                                                height={heightOptionsCard}
                                                msgOpen={msgAdboxOpen}
                                                displayOnlyPrice={motifHasOptions(_selectedTemplate, _adBoxNumberTypes) ? false : true}
                                            />
                                        </Box>)}
                                    </Box >
                                </section>
                            </Grid>
                        </Grid>

                        {hasAlternatives && (<Grid item xs={11}>
                            <Collapse in={fullLayout}
                                collapsedSize={collapsedSize + "px"}
                                orientation='vertical'>
                                <Stack
                                    direction='row'
                                    spacing={4}
                                    sx={{ width: '100%', height: height / 2, pr: 0 }}>
                                    <Box sx={CreativeToolAreaLayouts(toolAreaLayout).toolsActive} >
                                        <CreativeConfigCard disabled={onlyEdit}
                                            msgOpen={msgAdboxOpen}
                                            expanded={!fullLayout}
                                            handleExpand={handleExpand}
                                            ref={creativeToolCardRef} />
                                    </Box>
                                    <Box sx={CreativeToolAreaLayouts(toolAreaLayout).toolsInactive} >
                                        <InactiveCard
                                            title="creative.configCardTitle"
                                            message="creative.msgInactiveConfig"
                                            action={() => changeToolArea()} />
                                    </Box>
                                    <Box sx={CreativeToolAreaLayouts(toolAreaLayout).alternativesActive}>
                                        <AlternativesPicker
                                            width={width}
                                            expanded={!fullLayout}
                                            handleExpand={handleExpand}
                                            ref={alternativesCardRef} />
                                    </Box>
                                    <Box sx={CreativeToolAreaLayouts(toolAreaLayout).alternativesInactive} >
                                        <InactiveCard
                                            message="creative.msgInactiveAlternatives"
                                            action={() => changeToolArea()} />
                                    </Box>
                                    <Box sx={CreativeToolAreaLayouts(toolAreaLayout).orderToEditMsg} >
                                        <InactiveCard
                                            title="creative.orderToEditMsgCardTitle"
                                            message="creative.msgOrderToEditMsg" />
                                    </Box>
                                    <Box sx={CreativeToolAreaLayouts(toolAreaLayout).offersInactive} >
                                        {!onlyEdit && (<PriceCard
                                            /*  navAction={!_properties.displayOfferPage ? () => changeToolArea() : undefined} */
                                            displayOffer={false}
                                            displayMoreOfferText={false}
                                            displayMorePriceText={true}
                                            expanded={!fullLayout}
                                            handleExpand={handleExpand}
                                            ref={priceCardRef}
                                            height={height / 2 + collapsedSize - 40}
                                        />)}
                                        {onlyEdit && (<PriceCard />)}
                                    </Box>
                                </Stack>
                            </Collapse>
                        </Grid>)}
                    </Box>
                </Box >
                <AppFooter
                    action1={<Button disabled={_busy} variant="navigationBack" onClick={() => { back(); }} >{t('creative.back')}</Button>}
                    action2={<Button disabled={_busy || !_selectedOffer} variant="navigationAction" onClick={() => { next(); }}>{t('creative.buy')}</Button>}>
                </AppFooter>
                <InfoText />

                <UpsellingDialog
                    handleDialogClose={handleUpsellingClose}
                    dialogOpen={upsellingOpen}
                    selectedTemplate={_selectedTemplate}
                    alternatives={_upsellingAlternatives} />
            </Stack >
            <a style={{ visibility: "hidden" }}
                id="motif-download-as-pdf"
                rel="noreferrer"
                href={pdfUrl(_motifUuid)}
                target="_blank"
                download={`${_motifUuid}.pdf`}>{_motifUuid}.pdf</a >
        </>
    );
}

export default Creative;
